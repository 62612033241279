import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import IPost from "../types/IPost";
import SignUpPrompt from "../components/SignUpPrompt";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Loading from "../components/Loading";
import Error from "../components/Error";
import ShareModal from "../components/ShareModal";

import { IoPerson } from "react-icons/io5";
import { BsCalendar2DateFill } from "react-icons/bs";
import { IoIosShareAlt } from "react-icons/io";

import { useAuth } from "../context/AuthContext";
import { usePosts } from "../context/PostsContext"; 
import useFirebase from "../hooks/useFirebase";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

const BlogPost = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();

  const [blogPost, setBlogPost] = useState<IPost | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showPrompt, setShowPrompt] = useState(false);
  const [blurContent, setBlurContent] = useState(false);
  const [isShareModalOpen, setShareModalOpen] = useState(false); // State for modal

  const { getPostById, deletePost } = useFirebase();
  const { currentUser } = useAuth();
  const { posts } = usePosts();

  useEffect(() => {
    const existingPost = posts.find((post: any) => post.id === id);
    if (existingPost) {
      setBlogPost(existingPost);
      setLoading(false);
    } else {
      const fetchBlogPost = async () => {
        try {
          const fetchedBlog = (await getPostById(id!)) as IPost;
          setBlogPost(fetchedBlog);
          setLoading(false);
        } catch (err: any) {
          setError(err.message);
          setLoading(false);
        }
      };
      fetchBlogPost();
    }
  }, [id, posts, getPostById]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = window.innerHeight / 2;

      if (scrollPosition > threshold && (!currentUser && blogPost?.isPremiumOnlyPost)) {
        setShowPrompt(true);
        setBlurContent(true);
      } else if (scrollPosition <= threshold) {
        setShowPrompt(false);
        setBlurContent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [currentUser, blogPost]);

  if (loading) return <Loading />;
  if (error) return <Error message={error} />;
  if (!blogPost) return <div>No blog post found.</div>;

  return (
    <HelmetProvider>
      <div className="flex container relative mx-auto p-4 justify-center align-middle">
        <Helmet>
          <title>{blogPost.title}</title>
          <meta property="og:title" content={blogPost.title} />
          <meta property="og:url" content={`https://coolpress.com/post/${id}`} />
          <meta property="og:type" content="article" />
        </Helmet>

        <article
          className={`prose lg:prose-xl dark:prose-invert relative z-10 mx-auto ${blurContent ? "blur-sm" : ""}`}
          style={{ maxWidth: "800px", margin: "0 auto" }}
        >
          <header className="text-center mb-4">
            <h1 className="text-4xl font-bold">{blogPost.title}</h1>
            <div className="flex justify-center items-center space-x-4 mt-2">
              <div className="text-sm text-gray-600 dark:text-gray-400">
                <div className="flex flex-row">
                  <BsCalendar2DateFill size={18} className="mr-1" />
                  {new Date(blogPost.date).toLocaleDateString()}
                </div>
              </div>
              <div className="text-sm text-gray-600 dark:text-gray-400">
                <div className="flex flex-row">
                  <IoPerson size={18} className="ml-2 mr-1" />
                  {blogPost.authorName}
                </div>
              </div>
              {currentUser && currentUser.uid === blogPost.authorsId[0] && (
                <div className="flex space-x-4">
                  <button
                    onClick={() => {
                      if (window.confirm("Are you sure you want to DELETE this post?")) {
                        deletePost(id!);
                        navigate("/");
                        alert("Post deleted successfully!");
                      }
                    }}
                    className="text-md text-red-500 hover:text-red-700 bg-transparent px-3 py-1"
                  >
                    Delete
                  </button>
                  <button
                    onClick={() => {
                      navigate(`/write/${id}`);
                    }}
                    className="text-md text-blue-500 hover:text-blue-700 bg-transparent px-3 py-1"
                  >
                    Edit
                  </button>
                </div>
              )}
			  <button
				onClick={() => setShareModalOpen(true)}
				className="text-lg text-blue-500 hover:text-blue-700 bg-transparent px-3 py-1"
			>
				<div className="flex flex-row items-center">
				<IoIosShareAlt size={28} className="mr-1" />
				Share
				</div>
			</button>
            </div>
          </header>
          <img
            className="my-4 w-full overflow-hidden rounded-md object-cover object-center mx-auto"
            src={blogPost.mainImage}
            alt={blogPost.title}
            loading="lazy"
            style={{ maxWidth: "100%" }}
          />
          <section className="flex flex-1 justify-center">
            <div>
              <ReactMarkdown
                children={blogPost.body}
                className="markdown protected-content text-lg"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
              />
            </div>
          </section>
          <div className="flex items-center justify-center">
  <button
    onClick={() => setShareModalOpen(true)}
    className="text-lg text-blue-500 hover:text-blue-700 bg-transparent px-3 py-1 flex items-center"
  >
    <IoIosShareAlt size={28} className="mr-1" />
    Share
  </button>
</div>

        </article>


        {showPrompt && <SignUpPrompt location={location} />}
        {showPrompt && (
          <div className="absolute left-0 top-0 h-1/2 w-full bg-gradient-to-b from-transparent to-white dark:to-gray-800"></div>
        )}

        <ShareModal
          isOpen={isShareModalOpen}
          onClose={() => setShareModalOpen(false)}
          title={blogPost.title}
          url={`https://coolpress.com/post/${id}`}
        />
      </div>
    </HelmetProvider>
  );
};

export default BlogPost;
